<template>
  <div class="paging-component-wrapper">
    <span class="choose-length" v-if="checkListLength!=-1">已选择<span>{{checkListLength}}</span>项</span>
    <el-pagination layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20, 50, 100]"
      :total.sync="pageInfo.total" :current-page.sync="pageInfo.pageNum" :page-size.sync="pageInfo.pageSize"
      @size-change="sizeChange" @current-change="currentChange" />
  </div>
</template>

<script>
export default {
  name: "paging-component",
  data () {
    return {}
  },
  methods: {
    sizeChange () {
      this.pageInfo.pageNum = 1
      this.$emit('getTableData')
    },
    currentChange () {
      this.$emit('getTableData')
    },
  },
  props: {
    pageInfo: {
      type: Object,
      default () {
        return {}
      }
    },
    checkListLength: {
      default () {
        return -1
      }
    }
  }
}
</script>

<style lang="less" scoped>
.paging-component-wrapper {
  font-family: Microsoft YaHei;
  padding-top: 10px;
  text-align: right;
  ::v-deep .el-pagination {
    .el-pagination__total,
    .number,
    .el-pagination__jump,
    .el-input__inner {
      font-size: 18px;
    }
  }
}
.choose-length {
  display: inline-block;
  width: 95px;
  font-size: 16px;
  vertical-align: middle;
  // float: left;
  margin-top: 10px;
  span {
    color: #2577e3;
  }
}
</style>